<template>
  <a-spin :spinning="spinningLoadingPage">
    <div style="font-size: 0.9rem">
      <div class="row">
        <b-col sm="12">
          <b-card no-body class="border">
            <b-row>
              <b-col sm="12">
                <b-table :items="items" :fields="fields" thead-class="text-center" medium hover>
                  <template #cell(parameter)="row">
                    <span class="font-weight-bold text-dark w-100"> {{ row.item.hasOwnProperty("label") ? row.item.label : row.item.parameter }}</span>
                  </template>

                  <template #cell(min)="row">
                    <b-form-input @keydown="changeBtnColor(type_form,row.item.parameter)" v-model.number="row.item.min" size="sm" class="text-center" type="number" :disabled="row.item.disable != undefined && row.item.disable == true" />
                  </template>

                  <template #cell(max)="row">
                    <b-form-input @keydown="changeBtnColor(type_form,row.item.parameter)" size="sm" type="text" v-model.number="row.item.max" class="text-center" :disabled="row.item.disable != undefined && row.item.disable == true" />
                  </template>

                  <template #cell(special_value)="row">
                    <b-form-input @keydown="changeBtnColor(type_form,row.item.parameter)" size="sm" type="text" v-model="row.item.special_value" class="text-center" :disabled="row.item.disable != undefined && row.item.disable == true" />
                  </template>

                  <template #cell(action)="row">
                    <b-row class="" style="width:100px">
                      <b-button v-if="!(row.item.disable != undefined && row.item.disable == true)" @click="updateQC(row.item.parameter, row.item)" :variant="btnColor[type_form][row.item.parameter]" class="text-center" size="sm">Save</b-button>

                      <feather-icon
                        v-if="row.item.autorumus != undefined && row.item.autorumus == true"
                        icon="HelpCircleIcon"
                        size="14"
                        class="bg-success rounded-circle text-white text-center"
                        style="margin-top: 10px; margin-left: 5px"
                        v-b-tooltip.hover.v-dark
                        title="Source: QC Autorumus."
                      />
                    </b-row>
                  </template>

                  <!-- <template #cell(mandatory)="row">
								<b-form-select v-model="row.item.mandatory" :options="t_mandatory_option" size="sm" class="w-70" />
								</template> -->
                </b-table>
              </b-col>
            </b-row>
          </b-card>
          <!-- <b-row class="float-right">
						<div>
							<b-button variant="danger" class="mr-2" size="sm">Cancel</b-button>
							<b-button
								@click.prevent="update"
								variant="success"
								class="mr-2"
								size="sm"
								>Edit</b-button
							>
						</div>
					</b-row> -->
        </b-col>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard, BFormGroup, BFormSelect, BTable, BButton, BFormCheckbox, BFormInput, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import Service from "@/api/metadata";
import Params from "@/attr/metadata/params";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BFormGroup,
    BFormSelect,
    BTable,
    BButton,
    BFormCheckbox,
    BFormInput,
    vSelect,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["profile", "type_form", "refreshForm"],
  data() {
    return {
      t_mandatory_option: [
        { value: "N", text: "NO" },
        { value: "Y", text: "YES" },
      ],
      t_step_check_option: [
        { value: "enabled", text: "ENABLED" },
        { value: "disabled", text: "DISABLED" },
      ],
      t_pressure_mode: "",
      t_pressure_mode_option: [
        { value: "", text: "-" },
        { value: "derived", text: "derived" },
        { value: "stasiun", text: "stasiun" },
      ],
      fields: [
        {
          label: "Parameter",
          key: "parameter",
          thClass: "font-weight-bold",
          tdClass: "font-weight-bold text-left",
        },
        {
          label: "Min",
          key: "min",
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Max",
          key: "max",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Special Value",
          key: "special_value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          key: "action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      showLoading: false,
      spinningLoadingPage: false,
      isMustRefresh : false,
      btnColor : {},
    };
  },
  mounted() {
    this.pulldata()
  },
  methods: {
    async pulldata() {
      if (this.profile) {

        var list_params = Params.GET_PARAMS(this.type_form);
        for(var lp in list_params){
          var param = list_params[lp].parameter;
          if(this.btnColor[this.type_form] == undefined){
            this.btnColor[this.type_form] = {};
          }
          this.btnColor[this.type_form][param] = "primary"
        }
        this.items = Params.GET_PARAMS(this.type_form);
        let listparams = this.profile[Params.GET_QC_STATION_KEY(this.type_form)]; //this.profile["BmkgSatu.metadata.qc.IME48"];
        let datakey = Params.GET_KEY(this.type_form);
        for (var i in this.items) {
          for (const key in listparams) {
            if (key.indexOf(this.items[i].parameter.toLowerCase()) > -1) {
              this.items[i].min = listparams[datakey + this.items[i].parameter.toLowerCase() + "_min"];
              this.items[i].max = listparams[datakey + this.items[i].parameter.toLowerCase() + "_max"];
              this.items[i].special_value = listparams[datakey + this.items[i].parameter.toLowerCase() + "_values"];
              break;
            }
          }
        }
      }
    },
    changeBtnColor(type_form,parameter){
      this.btnColor[type_form][parameter] = "warning"
    },


    updateQC(param, item) {
      // console.log("updateQC - item", param, item);
      this.isMustRefresh = false
      let { path } = this.$route.query;
      let post_data = {};
      let datakey = Params.GET_KEY(this.type_form);
      let elementData = {};

      elementData[String(datakey + param + "_min")] = item?.min !== undefined && item.min !== "" ? parseFloat(item.min) : null;
      elementData[String(datakey + param + "_max")] = item?.max !== undefined && item.max !== "" ? parseFloat(item.max) : null;
      elementData[String(datakey + param + "_values")] = String(item.special_value);
      post_data[Params.GET_QC_STATION_KEY(this.type_form)] = elementData;

      if (this.type_form == "agm1b" || this.type_form == "suhu_tanah") {
        if (
          param == "ttg_5cm_0730" ||
          param == "ttg_10cm_0730" ||
          param == "ttg_20cm_0730" ||
          param == "ttg_5cm_1330" ||
          param == "ttg_10cm_1330" ||
          param == "ttg_20cm_1330" ||
          param == "ttg_5cm_1730" ||
          param == "ttg_10cm_1730" ||
          param == "ttg_20cm_1730" ||
          param == "ttg_50cm_1730" ||
          param == "ttg_100cm_1730" ||
          param == "ttr_5cm_0730" ||
          param == "ttr_10cm_0730" ||
          param == "ttr_20cm_0730" ||
          param == "ttr_5cm_1330" ||
          param == "ttr_10cm_1330" ||
          param == "ttr_20cm_1330" ||
          param == "ttr_5cm_1730" ||
          param == "ttr_10cm_1730" ||
          param == "ttr_20cm_1730" ||
          param == "ttr_50cm_1730" ||
          param == "ttr_100cm_1730"
        ) {
          let namekey_2 = this.type_form == "agm1b" ? "suhu_tanah" : "agm1b";
          let datakey_2 = Params.GET_KEY(namekey_2);
          let elementData_2 = {};
          elementData_2[String(datakey_2 + param + "_min")] = item?.min !== undefined && item.min !== "" ? parseFloat(item.min) : null;
          elementData_2[String(datakey_2 + param + "_max")] = item?.max !== undefined && item.max !== "" ? parseFloat(item.max) : null;
          elementData_2[String(datakey_2 + param + "_values")] = String(item.special_value);
          post_data[Params.GET_QC_STATION_KEY(namekey_2)] = elementData_2;
          this.isMustRefresh = true
        }
      }

      if (this.type_form == "fklim" || this.type_form == "agm1a" || this.type_form == "op_penguapan") {
        if (param == "rr_0700") {
          let namekey_2 = "fklim";
          let namekey_3 = "fklim";
          if (this.type_form == "fklim") {
            namekey_2 = "agm1a";
            namekey_3 = "op_penguapan";
          } else if (this.type_form == "agm1a") {
            namekey_2 = "fklim";
            namekey_3 = "op_penguapan";
          } else {
            namekey_2 = "fklim";
            namekey_3 = "agm1a";
          }
          let datakey_2 = Params.GET_KEY(namekey_2);
          let datakey_3 = Params.GET_KEY(namekey_3);
          let elementData_2 = {};
          elementData_2[String(datakey_2 + param + "_min")] = item?.min !== undefined && item.min !== "" ? parseFloat(item.min) : null;
          elementData_2[String(datakey_2 + param + "_max")] = item?.max !== undefined && item.max !== "" ? parseFloat(item.max) : null;
          elementData_2[String(datakey_2 + param + "_values")] = String(item.special_value);
          post_data[Params.GET_QC_STATION_KEY(namekey_2)] = elementData_2;

          let elementData_3 = {};
          elementData_3[String(datakey_3 + param + "_min")] = item?.min !== undefined && item.min !== "" ? parseFloat(item.min) : null;
          elementData_3[String(datakey_3 + param + "_max")] = item?.max !== undefined && item.max !== "" ? parseFloat(item.max) : null;
          elementData_3[String(datakey_3 + param + "_values")] = String(item.special_value);
          post_data[Params.GET_QC_STATION_KEY(namekey_3)] = elementData_3;
          this.isMustRefresh = true
        }
      }

      // console.log("postdata", post_data);
      this.spinningLoadingPage = true;
      Service.patchStasiun(path, post_data)
        .then((response) => {
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            // console.log("param : ", this.btnColor[this.type_form])
            // console.log("this.type_form : ",this.type_form)
            // this.btnColor[this.type_form][param] = "primary"
            
            // AN : Force update UI
            this.btnColor = {
              ...this.btnColor,
              [this.type_form]: {
                ...this.btnColor[this.type_form],
                [param]: "primary"
              }
            };
            // console.log("param : ", this.btnColor[this.type_form])
            // console.log(this.btnColor[this.type_form])
            // console.log("oke")
            this.spinningLoadingPage = false;
            if (this.isMustRefresh) {
              this.$emit("form", "refresh");
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Parameter berhasil diupdate",
                icon: "BellIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          this.spinningLoadingPage = false;
          if (error.response.status == 401) {
            this.$swal({
              title: "Unauthorized!",
              text: "Sesi anda telah habis. Logout dan silahkan login kembali",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (error.response.status == 409) {
          } else {
            this.$swal({
              title: "Something wrong!",
              text: "" + error.response.status,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.showLoading = false;
        });
    },

    generateData() {
      let listparams = this.profile[Params.GET_QC_STATION_KEY(this.type_form)];
      let data = {};

      for (const key in listparams) {
        for (var i in this.items) {
          if (key.indexOf(this.items[i].parameter.toLowerCase()) > -1) {
            if (key.indexOf("_min") > -1) {
              if (this.items[i]?.min !== undefined && this.items[i].min !== "") {
                data[key] = this.items[i].min;
              } else {
                data[key] = null;
              }
            } else if (key.indexOf("_max") > -1) {
              if (this.items[i]?.max !== undefined && this.items[i].max !== "") {
                data[key] = this.items[i].max;
              } else {
                data[key] = null;
              }
            } else if (key.indexOf("_values") > -1) {
              if (this.items[i]?.special_value !== undefined && this.items[i].special_value !== "") {
                data[key] = this.items[i].special_value;
              } else {
                data[key] = null;
              }
            }
            // else if (key.indexOf("_mandatory") > -1) {
            //   data[key] = this.items[i].mandatory;
            // }
            break;
          }
        }
      }

      return data;
    },
    // update() {
    // 	let { path } = this.$route.query;
    // 	let data = this.generateData();
    // 	let post_data = {
    // 		// "@type": "BmkgStation",
    // 	};

    // 	// "BmkgSatu.metadata.qc.IME48": data,
    // 	post_data[Params.GET_QC_STATION_KEY(this.type_form)] = data;
    // 	console.log("update - post_data", post_data);
    // 	Service.patchStasiun(path, post_data)
    // 		.then((response) => {
    // 			if (
    // 				response.status == 201 ||
    // 				response.status == 200 ||
    // 				response.status == 204
    // 			) {
    // 				this.$swal({
    // 				title: "Update Metadata Berhasil",
    // 				text: "Metadata " + this.type_form,
    // 				icon: "success",
    // 				customClass: {
    // 					confirmButton: "btn btn-primary",
    // 				},
    // 				buttonsStyling: false,
    // 				});
    // 			}
    // 		})
    // 		.catch((error) => {
    // 			if (error.response.status == 401) {
    // 				this.$swal({
    // 				title: "Unauthorized!",
    // 				text: "Sesi anda telah habis. Logout dan silahkan login kembali",
    // 				customClass: {
    // 					confirmButton: "btn btn-primary",
    // 				},
    // 				buttonsStyling: false,
    // 				});
    // 			} else {
    // 				this.$swal({
    // 				title: "Something wrong!",
    // 				text: "" + error.response.status,
    // 				icon: "warning",
    // 				customClass: {
    // 					confirmButton: "btn btn-primary",
    // 				},
    // 				buttonsStyling: false,
    // 				});
    // 			}
    // 			this.showLoading = false;
    // 		});
    // },
  },
  watch: {
    profile: function (val) {
      // console.log('rccomponent', val)
      this.pulldata()
    }
  }
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
