<template>
	<a-spin :spinning="spinningLoadingPage">
		<div style="font-size: 0.9rem">
			<div class="row">
				<b-col cols="12">
					<b-card no-body class="border">
						<b-row>
							<b-col cols="12">
								<b-table
									responsive="md" 
									:items="items"
									:fields="fields"
									thead-class="text-center"
									medium
									striped
            						hover
								>								

									<template #cell(qc_type)="row">
										<span class="font-weight-bold text-dark w-100"> {{ toTitleCase(row.item.qc_type) }}</span>
									</template>

								</b-table>
							</b-col>
						</b-row>
					</b-card>
					
				</b-col>
			</div>
		</div>
	</a-spin>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BTable,
  BButton,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Service from "@/api/metadata";
export default {
	components: {
	BTab,
	BRow,
	BCol,
	BCard,
	BTabs,
	BFormGroup,
	BFormSelect,
	BTable,
	BButton,
	BFormCheckbox,
	BFormInput,
	vSelect,
	},
	props: ["profile", "type_form"],
	data() {
		return {
			spinningLoadingPage: false,
			fields: [
				{
					label: 'Date',
					key: 'date',
				},
				{
					label: 'Edit By',
					key: 'edit_by',
				},
				{
					label: 'QC Type',
					key: 'qc_type',
				},
				{
					label: 'Parameter',
					key: 'parameter',
				},
				{
					label: 'Current Value',
					key: 'current_value',
				},
				{
					label: 'New Value',
					key: 'new_value',
				},
			],
			items: [],
		};
	},
	mounted() {
		// console.log('type_form', this.type_form)
		this.initialize()
		this.getListLogQC()
	},
	methods: {

		toTitleCase(str) {
			const arr = str.split("_");
			const result = [];
			for (const word of arr) {
				result.push(word.charAt(0).toUpperCase() + word.slice(1));
			}
			return result.join(" ");
		},

		initialize() {
			let typeform = this.type_form
			if (typeform == 'pilot_balon') {
				this.type_form = 'pibal'
			}
			else if (typeform == 'hujan_helman') {
				this.type_form = 'hujanhellman'
			}
			else if (typeform == 'kimia_air_hujan') {
				this.type_form = 'kimiaairhujan'
			}
			else if (typeform == 'form_hujan_harian') {
				this.type_form = 'formhujanharian'
			}
			else if (typeform == 'suspended_particulate_matter') {
				this.type_form = 'suspendedparticulatematter'
			}
			else {
				this.type_form = typeform.replace('_','');
			}
			
		},

		getListLogQC() {  
			new Promise(async (resolve) => {
				this.spinningLoadingPage = true
				let { path } = this.$route.query;
				const{ data } = await Service.getLogQC(path, this.type_form)
				console.log('data: ', data)
				// let logdata = []
				var datalog = data.items;
				if (datalog) {
					datalog.forEach(element => {
						let tgl = element.id.split('_')
						let params = JSON.parse(element.qc_atribut.replace(/'/g, '"'));
						// let oldValue = JSON.parse(element.qc_old_value.replace(/'/g, '"'));
						// let newValue = JSON.parse(element.qc_new_value.replace(/'/g, '"'));
						var dOldVal = element.qc_old_value;
						var dOldVal_1 = dOldVal.replace("[","");
						var dOldVal_2 = dOldVal_1.replace("]","");
						var oldValue = dOldVal_2.split(",")
						var dNewVal = element.qc_new_value;
						var dNewVal_1 = dNewVal.replace("[","");
						var dNewVal_2 = dNewVal_1.replace("]","");
						var newValue = dNewVal_2.split(",")
						let parameter = ''
						let messageCurrentValues = ''
						let messageNewValues = ''
						if(params) {
							params.forEach((element, i) => {
								if( element.indexOf('_values') >= 0) {
									parameter = element.split('_values')[0]
									var new_special = newValue[i]
									if(newValue[i] == "null" || newValue[i]=="'null'" || newValue[i]=="None" || 
										newValue[i] == "'None'" || newValue[i] == ""){
											new_special = "";
									}

									var old_special = oldValue[i];
									if(oldValue[i] == "null" || oldValue[i]=="'null'" || oldValue[i]=="None" || 
										oldValue[i] == "'None'" || oldValue[i] == ""){
											old_special = "";
									}
									messageCurrentValues += "Special Value: "+old_special + "; "
									messageNewValues += "Special Value: "+new_special+ "; "
								}
								else {
									if(i < 3) {
										if( element.indexOf('_min') >= 0) {
											var new_min = newValue[i]
											if(newValue[i] == "null" || newValue[i]=="'null'" || newValue[i]=="None" || 
												newValue[i] == "'None'" || newValue[i] == ""){
												new_min = "";
											}
											var old_min = oldValue[i];
											if(oldValue[i] == "null" || oldValue[i]=="'null'" || oldValue[i]=="None" || 
												oldValue[i] == "'None'" || oldValue[i] == ""){
												old_min = "";
											}
											messageCurrentValues += "Min: "+old_min + "; "
											messageNewValues += "Min: "+new_min + "; "
										}
										else if( element.indexOf('_max') >= 0) {
											var new_max = newValue[i]
											if(newValue[i] == "null" || newValue[i]=="'null'" || newValue[i]=="None" || 
												newValue[i] == "'None'" || newValue[i] == ""){
												new_max = "";
											}
											var old_max = oldValue[i];
											if(oldValue[i] == "null" || oldValue[i]=="'null'" || oldValue[i]=="None" || 
												oldValue[i] == "'None'" || oldValue[i] == ""){
												old_max = "";
											}
											messageCurrentValues += "Max: "+old_max + "; "
											messageNewValues += "Max: "+new_max + "; "
										}
									}
								}
							});
						}
						
						// let currentValues = JSON.parse(element.qc_old_value)
						// let messageCurrentValues = "Min: " +currentValues[2]+ ", Max: "+currentValues[1]+", Special Value: "+currentValues[0]
						// let newValues = JSON.parse(element.qc_new_value)
						// let messageNewValues = "Min: " +newValues[2]+ ", Max: "+newValues[1]+", Special Value: "+newValues[0]
						var date_table = tgl[2].split('T')[0].replace(/-/g,"/");
						var time_table = tgl[2].split('T')[1];
						var new_time = time_table.replace(/-/g,":");
						// if(element.qc_timestamp != undefined){
						// 	date_table = new Date(element.qc_timestamp).toLocaleString("en-ID");
						// }
						if(element.creation_date != undefined){
							var tgl_baru = new Date(element.creation_date).toLocaleString("id", {timeZone: "Asia/Jakarta"})
							var covertToMin = tgl_baru.replaceAll("/", "-");
							var date_table = covertToMin.replaceAll(".", ":");

						}
						console.log(new_time);
						this.items.push({
							date: date_table,
							edit_by: element.username,
							qc_type: element.qc_type,
							parameter: parameter,
							current_value: messageCurrentValues,
							new_value: messageNewValues
						})
					});

					this.items.sort((a, b) => {
						return Date.parse(b.date) - Date.parse(a.date);
					});
				}

				// this.items = logdata

				this.spinningLoadingPage = false
				resolve(data);
			});
		}
	},
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
