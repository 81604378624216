<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-row>
            <b-col cols="2"> </b-col>
            <b-col cols="10" class="mb-2">* Untuk menginput Nilai Min dan Max Range Check mengacu pada Nilai Gross Check pada Form Entri </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-tabs vertical nav-wrapper-class="nav-vertical">
                <!-- <b-tab title="Range Check" active> <range-check :profile="profile"/></b-tab>
                <b-tab title="Step Check" > <step-check :profile="profile"/></b-tab>
                <b-tab title="Pressure Correction" lazy> <pressure-correction /> </b-tab>
                <b-tab title="Synop" lazy> <synop /> </b-tab>
                <b-tab title="Metar" lazy> <metar /> </b-tab> -->
                <b-tab title="Range Check" active> <range-check :type_form="type_form" :profile="profile"  @form="onRefreshForm($event)" /></b-tab>
                <b-tab v-if="type_form == 'me48'" title="Step Check"> <step-check :type_form="type_form" :profile="profile" /></b-tab>
                <b-tab v-if="type_form == 'me48'" title="Pressure Settings" lazy> <pressure-correction :type_form="type_form" :profile="profile" /> </b-tab>
                <!-- <b-tab v-if="type_form == 'me48'" title="Synop" lazy> <synop :type_form="type_form" :profile="profile"/> </b-tab>
                <b-tab v-if="type_form == 'me48'" title="Metar" lazy> <metar :type_form="type_form" :profile="profile"/> </b-tab> -->
                <b-tab title="Log" lazy> <log-qc :type_form="type_form" :profile="profile" /> </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard } from "bootstrap-vue";
import RangeCheck from "./componenttabs/rccomponent.vue";
import StepCheck from "./componenttabs/sccomponent.vue";
import PressureCorrection from "./componenttabs/pressurecorrectioncomponent.vue";
import Synop from "./componenttabs/sinopcomponent.vue";
import Metar from "./componenttabs/metarcomponent.vue";
import LogQc from "./componenttabs/logcomponent.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    Metar,
    Synop,
    PressureCorrection,
    RangeCheck,
    StepCheck,
    LogQc,
  },
  props: ["profile", "type_form"],
  mounted() {
    // console.log("me48 : ", this.profile);
  },
  methods: {
    onRefreshForm(event) {
      // console.log('BeForms - onRefreshForm', event)
      this.$emit("form", "refresh");
    },
  },
};
</script>
<style scoped>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
