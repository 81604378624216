<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-tabs vertical nav-wrapper-class="nav-vertical">
            <!-- <b-tab title="Range Check" active> <range-check :profile="profile"/></b-tab>
            <b-tab title="Step Check" > <step-check :profile="profile"/></b-tab>
            <b-tab title="Pressure Correction" lazy> <pressure-correction /> </b-tab>
            <b-tab title="Synop" lazy> <synop /> </b-tab>
            <b-tab title="Metar" lazy> <metar /> </b-tab> -->
            <!-- <b-tab title="Range Check" active> <range-check :type_form="type_form" :profile="profile"/></b-tab> -->

            <b-tab title="Range Check" active>
              <div class="container">
                <b-col cols="8" sm="8" lg="8" md="8">
                  <b-card class="border ml-n1">
                    <b-row>
                      <b-col cols="8">
                        <b-form-group label="Jumlah Tahun Data" label-for="input-wmo" label-cols-sm="6">                           
                          <b-form-input id="input-wmo" v-model.number="station_autorumus_years" size="sm" style="height: 32px;" />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" style="margin-top: 3px;">
                        <label style="font-size: 15px;">Tahun</label>
                        <feather-icon
                            icon="HelpCircleIcon"
                            size="14"
                            class="bg-success rounded-circle text-white text-center"
                            style="margin-left: 5px"
                            v-b-tooltip.hover.v-dark
                            title="Jumlah data (tahun) yang digunakan sebagai sumber perhitungan QC Auto Rumus. Default 30 tahun"
                          />
                      </b-col>
                      <b-col cols="2">
                        <b-button @click="updateYearData" variant="success" class="mr-2" size="sm">Submit</b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </div>
              <div class="container">
                <b-col cols="10" sm="12" lg="10" md="10">
                  <!-- RH -->
                  <b-row >
                    <b-col cols="5" class="header-qc"><b>PARAMETER</b></b-col>
                    <b-col cols="2" class="header-qc  center-text"><b>MIN</b></b-col>
                    <b-col cols="2" class="header-qc center-text"><b>MAX</b></b-col>
                    <b-col cols="2" class="header-qc center-text"><b>ACTION</b></b-col>
                  </b-row>

                  <!-- QFF -->
                  <b-row class="qc-row-head mt-1">
                    <b-col cols="5">Tekanan QFF</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_pressure_qff_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_pressure_qff_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('pressure_qff_mb_derived','QFF')">Update</b-button></b-col>
                  </b-row>
                  <!-- <b-row class="qc-row" v-if="qff_child">
                    <b-col cols="5">MetarSpeci - Pressure (QNH)</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_pressure_qff_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_pressure_qff_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>
                  <!-- QFE -->
                  <b-row class="qc-row-head">
                    <b-col cols="5">Tekanan QFE</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_pressure_qfe_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_pressure_qfe_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('pressure_qfe_mb_derived','QFE')">Update</b-button></b-col>
                  </b-row>
                  <!-- <b-row class="qc-row" v-if="qfe_child">
                    <b-col cols="5">Barograf - Tekanan Udara per Jam</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_pressure_qfe_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_pressure_qfe_mb_derived"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>
                  <!-- Suhu Bola Kering -->
                  <b-row class="qc-row-head">
                    <b-col cols="5">Suhu Bola Kering</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('temp_drybulb_c_tttttt','Suhu Bola Kering')">Update</b-button></b-col>
                  </b-row>
                  

                  <!-- <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">MetarSpeci - Air Temp</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Termohigrograf - Suhu Udara Per Jam</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 07.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 07.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 13.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 13.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 14.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 17.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Sangkar - TBK 18.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>

                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Assman - TBK 07.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Assman - TBK 13.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbk_child">
                    <b-col cols="5">Psycrometer Assman - TBK 17.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_drybulb_c_tttttt"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>
                  <!-- Suhu Bola Basah -->
                  <b-row class="qc-row-head">
                    <b-col cols="5">Suhu Bola Basah</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('temp_wetbulb_c','Suhu Bola Basah')">Update</b-button></b-col>
                  </b-row>

                  <!-- <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 07.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 07.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 13.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 13.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 14.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 17.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Sangkar - TBB 18.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>

                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Assman - TBB 07.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Assman - TBB 13.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row class="qc-row" v-if="sbb_child">
                    <b-col cols="5">Psycrometer Assman - TBB 17.30WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_wetbulb_c"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>

                  <b-row class="qc-row-head">
                    <b-col cols="5">Relative Humidity - RH</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-button class="btn btn-success btn-sm" @click="updateRumus('relative_humidity_pc','RH')">Update</b-button>
                    </b-col>
                  </b-row>
                  <!-- <b-row class="qc-row" v-if="rh_child">
                    <b-col cols="5">Psycrometer Sangkar - RH 14.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <!-- <b-row class="qc-row" v-if="rh_child">
                    <b-col cols="5">Thermohigograph - Kelembaban Udara per Jam</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_relative_humidity_pc"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>

                  <!-- Suhu Maksimum -->
                  <b-row>
                    <b-col cols="5">Suhu Maksimum</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_max_c_txtxtx"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_max_c_txtxtx"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('temp_max_c_txtxtx','Suhu Maksimum')">Update</b-button></b-col>
                  </b-row>
                  <hr>
                  <!-- Suhu Minimum -->
                  <b-row class="qc-row-head">
                    <b-col cols="5">Suhu Minimum</b-col>
                    <b-col cols="2" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_min_c_tntntn"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_min_c_tntntn"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2" class="text-center"><b-button class="btn btn-success btn-sm" @click="updateRumus('temp_min_c_tntntn','Suhu Minimum')">Update</b-button></b-col>
                  </b-row>
                  <!-- <b-row class="qc-row" v-if="smin_child">
                    <b-col cols="5">FDIH - Suhu Minimum 13.00WS</b-col>
                    <b-col cols="3" class="center-text">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="min_temp_min_c_tntntn"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        type="number"
                        size="sm"
                        class="text-center mbtm"
                        v-model="max_temp_min_c_tntntn"
                        readonly
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row> -->
                  <b-row >
                    <b-col cols="11">
                      <hr>
                    </b-col>
                  </b-row>
                </b-col>
              </div>  
              
            </b-tab>
            <!-- <b-tab title="QFF"  lazy> QFF  </b-tab>
            <b-tab title="QFE"  lazy>QFE  </b-tab>
            <b-tab title="Suhu Bola Kering"  lazy>Suhu Bola Kering  </b-tab>
            <b-tab title="Suhu Bola Basah "  lazy>Suhu Bola Basah  </b-tab>
            <b-tab title="Suhu Maksimum"  lazy>Suhu Maksimum  </b-tab>
            <b-tab title="Suhu Minimum"  lazy>Suhu Minimum  </b-tab> -->


          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import { BOverlay,BTab, BTabs, BRow, BCol, BCard,BFormInput, BFormGroup, BButton, VBTooltip } from "bootstrap-vue";
import RangeCheck from "./componenttabs/rccomponent.vue";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import Service from "@/api/metadata";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BOverlay,
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BFormInput,
    RangeCheck,
    BFormGroup,
    BButton,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: ["profile"],
  data: function() {
    return{
      showLoading : false,
      rh_child : true,
      qff_child : true,
      qfe_child : true,
      sbk_child : true,
      sbb_child : true,
      smin_child : true,
      path : "",
      station_id:"",
      qc_auto : {},
      min_relative_humidity_pc : "",
      max_relative_humidity_pc : "",

      min_temp_min_c_tntntn : "",
      max_temp_min_c_tntntn : "",

      min_temp_max_c_txtxtx  : "",
      max_temp_max_c_txtxtx  : "",

      min_temp_wetbulb_c : "", 
      max_temp_wetbulb_c : "",

      min_temp_drybulb_c_tttttt : "", 
      max_temp_drybulb_c_tttttt : "",

      min_pressure_qfe_mb_derived : "", 
      max_pressure_qfe_mb_derived : "", 

      min_pressure_qff_mb_derived : "",
      max_pressure_qff_mb_derived : "",

      station_autorumus_years: '',
      isMustRefresh : false
    }
  },
  
  mounted() {
    this.path = this.$route.query.path
    this.getData();
    // console.log("path route : ", this.$route.query.path);
    //  setTimeout(() => {
    //       console.log("Profilenya : ", this.profile);         
    //     }, 3000);
    this.station_autorumus_years = this.profile["station_autorumus_years"]
  },
  watch :{
    profile: function (val) {
      console.log('val profile', val)
      if (val) {
        this.station_autorumus_years = this.profile["station_autorumus_years"]
      }
    }
  },
  methods: {

    async updateYearData() {
      // console.log('station_autorumus_years', this.station_autorumus_years)
      let { path } = this.$route.query;
      let post_data = {"station_autorumus_years": this.station_autorumus_years ? this.station_autorumus_years : null };
      this.isMustRefresh = true
      Service.patchStasiun(path, post_data)
        .then((response) => {
          console.log('updateYearData', response)
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            this.spinningLoadingPage = false;
            if (this.isMustRefresh) {
              this.$emit("form", "refresh");
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Parameter berhasil diupdate",
                icon: "BellIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          this.spinningLoadingPage = false;
          if (error.response.status == 401) {
            this.$swal({
              title: "Unauthorized!",
              text: "Sesi anda telah habis. Logout dan silahkan login kembali",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (error.response.status == 409) {

          } else {
            this.$swal({
              title: "Something wrong!",
              text: "" + error.response.status,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.showLoading = false;
        });
    },
    
    async getData(){
      this.showLoading = true;
      this.station_autorumus_years = this.profile["station_autorumus_years"]
      var path = this.path
      const {data} = await metadata.getMetaQcAuto(path);
      this.station_id = data.station_id
      this.qc_auto = data["BmkgSatu.metadata.qc.IAutorumus_qc"]
      console.log("data axios : ",this.qc_auto)  

      this.min_relative_humidity_pc = this.qc_auto.qc_autorumus_relative_humidity_pc_min;
      this.max_relative_humidity_pc = this.qc_auto.qc_autorumus_relative_humidity_pc_max;

      this.min_temp_min_c_tntntn = this.qc_auto.qc_autorumus_temp_min_c_tntntn_min;
      this.max_temp_min_c_tntntn = this.qc_auto.qc_autorumus_temp_min_c_tntntn_max;

      this.min_temp_max_c_txtxtx  = this.qc_auto.qc_autorumus_temp_max_c_txtxtx_min;
      this.max_temp_max_c_txtxtx  = this.qc_auto.qc_autorumus_temp_max_c_txtxtx_max;

      this.min_temp_wetbulb_c = this.qc_auto.qc_autorumus_temp_wetbulb_c_min; 
      this.max_temp_wetbulb_c = this.qc_auto.qc_autorumus_temp_wetbulb_c_max;

      this.min_temp_drybulb_c_tttttt = this.qc_auto.qc_autorumus_temp_drybulb_c_tttttt_min; 
      this.max_temp_drybulb_c_tttttt = this.qc_auto.qc_autorumus_temp_drybulb_c_tttttt_max;

      this.min_pressure_qfe_mb_derived = this.qc_auto.qc_autorumus_pressure_qfe_mb_derived_min; 
      this.max_pressure_qfe_mb_derived = this.qc_auto.qc_autorumus_pressure_qfe_mb_derived_max; 

      this.min_pressure_qff_mb_derived = this.qc_auto.qc_autorumus_pressure_qff_mb_derived_min;
      this.max_pressure_qff_mb_derived = this.qc_auto.qc_autorumus_pressure_qff_mb_derived_max;

      setTimeout(() => {
          this.showLoading = false;        
        }, 3000);
      // console.log("min_relative_humidity_pc: ",this.min_relative_humidity_pc);
    },
    async prosesRumus(attr,label){
      this.showLoading = true;
      // console.log("ATTR : ",attr)
      var qc_path = "@autorumus?type_name=Sinoptik&station_id="+this.station_id+
                    "&_metadata="+attr+",path&_size=10000000"
      var post_data = {}
      var url = helper.getBaseUrl()+"/"+qc_path;
      var status = "error";
      await axios
        .post(url, post_data, helper.getConfig())
        .then((response) => {
          this.showLoading = false;
          
          this.$swal({
            title: "Berhasil!",
            text: "Update Nilai QC  " + label + " Berhasil",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          status = "success"
        })
        .catch((error) => {
          if (error.response) {
            this.showLoading = false;
            this.$swal({
              title: "Update Error!",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
      if(status == "success"){
        this.getData();
      }
    },
    updateRumus(e,parameter){
      this.$swal({
              title: "Apakah Anda Yakin?",
              text: "Update Nilai QC Parameter "+parameter,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "UPDATE QC",
              cancelButtonText : "BATAL",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.prosesRumus(e,parameter);
                // console.log(parameter)
              } else if (result.dismiss === "cancel") {
                
                return;
              }
            });
    },
  }
};
</script>
<style scoped>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
.center-text {
  text-align:center;
}
.qc-row{
  padding-bottom: 3px;
  padding-top: 3px;
}
.qc-row :hover{
  background: rgb(209 212 215);

}
.qc-row-head{
  padding-bottom: 3px;
  padding-top: 3px;
}
.qc-row-head :hover{
  background: rgb(209 212 215);

}
.header-qc{
  margin: 0px 0px 0px 0px;
  border: 1px #fff solid;
  background: rgb(124, 190, 245);
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
