<template>
  <div style="font-size: 1.00rem;">
    <div class="row">
      <b-col sm="6">
        <b-card no-body class="border">
          <!-- table list parameter -->
          <b-row class="font-weight-bolder ml-1 mt-1 mb-1">
            <b-col sm="12"> LIST PARAMETER</b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-table hover :items="items" :fields="fields" thead-class="text-center" medium @row-clicked="showStepCheck">
                <template #cell(parameter)="row">
                  <span class="font-weight-bold text-dark w-50 text-center"> {{ row.item.label }}</span>
                </template>

                <!-- <template #cell(step_check)="row"> -->
                <template #cell(action)="row">
                  <b-row
                    class="d-flex justify-content-center"
                    v-if="row.item.parameter == 'pressure_QFF_mb_derived' || row.item.parameter == 'pressure_QFE_mb_derived' || 
                          row.item.parameter == 'temp_drybulb_c_tttttt' || row.item.parameter == 'temp_wetbulb_c' ||
                          row.item.parameter == 'relative_humidity_pc' || row.item.parameter == 'temp_max_c_txtxtx' || row.item.parameter == 'temp_min_c_tntntn'"
                  >
                    <!-- <b-button
                      @click="showStepCheck(row.item, row.index, $event.target)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="light"
                      class="btn-icon"
                      size="sm"
                      v-b-tooltip.hover.v-dark
                      title="View and setting Step Check"
                    > -->
                     <div
                      variant="light"
                      size="sm"
                      v-b-tooltip.hover.v-dark
                      title="View and setting Step Check"
                    >
                      <feather-icon icon="ChevronRightIcon" />
                    </div>
                  <!-- </b-button> -->
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <!-- <b-row class="float-right">
          <div>
            <b-button variant="danger" class="mr-2" size="sm">Cancel</b-button>
            <b-button @click.prevent="update" variant="success" class="mr-2" size="sm">Edit</b-button>
          </div>
        </b-row> -->
      </b-col>

      <b-col sm="6" v-show="selectedParameter!=''">
        <b-card no-body class="border">
          <b-row class="font-weight-bolder ml-1 mt-1 mb-1">
            <b-col sm="12"> STEP CHECK FOR "{{selectedParameter.toUpperCase()}}"</b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-table :items="detailItems" :fields="fieldsDetail" thead-class="text-center" medium>
                <template #cell(hour)="row">
                  <span class="font-weight-bold text-dark w-50 text-center"> {{ row.item.hour }}</span>
                </template>

                <template #cell(before)="row">
                  <!-- <span class="font-weight-bold text-dark w-50 text-center"> {{ row.item.before }}</span> -->
                  <b-form-input size="sm" type="text" v-model.number="row.item.before" class="text-center" />
                </template>

                <template #cell(after)="row">
                  <!-- <span class="font-weight-bold text-dark w-50 text-center"> {{ row.item.after }}</span> -->
                  <b-form-input size="sm" type="text" v-model.number="row.item.after" class="text-center" />
                </template>

              </b-table>
            </b-col>
          </b-row>
          <b-row class="m-2">
            <b-col cols="12">
              <b-button  @click.prevent="saveStepCheckContent" variant="success" class="float-right" size="sm" :disabled="selectedParameter==''">Save</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>

    <b-modal ref="modal-step-check" id="modal-step-check" title="Step Check" hide-footer>
      <div>
        <b-card no-body class="border">
          <!-- table detail stepcheck-->
          <b-row class="font-weight-bolder ml-1 mt-1 mb-1">
            <b-col sm="12"> STEP CHECK FOR "{{selectedParameter.toUpperCase()}}"</b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-table :items="detailItems" :fields="fieldsDetail" thead-class="text-center" medium>
                <template #cell(label)="row">
                  <span class="font-weight-bold text-dark w-50 text-center"> {{ row.item.label }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-row class="float-right">
        <div>
          <b-button @click.prevent="cancelStepCheck" variant="danger" class="mr-2" size="sm">Cancel</b-button>
          <b-button @click.prevent="saveStepCheck" variant="success" class="mr-2" size="sm">Save</b-button>
        </div>
      </b-row>
    </b-modal>

    <!-- <b-modal ref="modal-step-check" id="modal-step-check" title="Step Check" hide-footer>
      <div>
        <div class="font-weight-bolder mb-2 mt-1 ml-1">Parameter : {{ titleStepcheck }}</div>
        <b-table :items="scItems" :fields="scFields" thead-class="text-center" small>
          <template #cell(before)="row">
            <b-form-input v-model="row.item.before" size="sm" class="text-center" type="number" />
          </template>
          <template #cell(after)="row">
            <b-form-input v-model="row.item.after" size="sm" class="text-center" type="number" />
          </template>
        </b-table>
      </div>
      <b-row class="float-right">
        <div>
          <b-button @click.prevent="cancelStepCheck" variant="danger" class="mr-2" size="sm">Cancel</b-button>
          <b-button @click.prevent="saveStepCheck" variant="success" class="mr-2" size="sm">Save</b-button>
        </div>
      </b-row>
    </b-modal> -->
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard, BFormGroup, BFormSelect, BTable, BButton, BFormCheckbox, BFormInput, BModal, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import Service from "@/api/metadata";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BFormGroup,
    BFormSelect,
    BTable,
    BButton,
    BFormCheckbox,
    BFormInput,
    BModal,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["profile"],
  data() {
    return {
      showLoading: false,
      titleStepcheck: "",
      t_mandatory_option: [
        { value: "N", text: "NO" },
        { value: "Y", text: "YES" },
      ],
      t_step_check_option: [
        { value: "enabled", text: "ENABLED" },
        { value: "disabled", text: "DISABLED" },
      ],
      scFields: ["hour", "before", "after"],
      scItems: [],
      fields: ["parameter", "action"],//"step_check"],
      items: [
        { key: "pressure_QFF_mb_derived", parameter: "pressure_QFF_mb_derived", step_check: "disabled", label: "Tekanan QFF  - (pressure_QFF_mb_derived)" },
        { key: "pressure_QFE_mb_derived", parameter: "pressure_QFE_mb_derived", step_check: "disabled", label: "Tekanan QFE  - (pressure_QFE_mb_derived)" },
        { key: "temp_drybulb_c_tttttt", parameter: "temp_drybulb_c_tttttt", step_check: "disabled", label: "Suhu Bola Kering (℃) - (temp_drybulb_c_tttttt)" },
        { key: "temp_wetbulb_c", parameter: "temp_wetbulb_c", step_check: "disabled", label: "Suhu Bola Basah (℃) - (temp_wetbulb_c)", _rowVariant: '' },
        // { key: "temp_max_c_txtxtx", parameter: "temp_max_c_txtxtx", step_check: "disabled", label: "Suhu Maximum (℃) - (temp_max_c_txtxtx)" },
        // { key: "temp_min_c_tntntn", parameter: "temp_min_c_tntntn", step_check: "disabled", label: "Suhu Minimum (℃) - (temp_min_c_tntntn)" },
        { key: "relative_humidity_qc", parameter: "relative_humidity_pc", step_check: "disabled", label: "RH - (relative_humidity_pc)" },
      ],

      selectedParameter: "",
      metaParameter:"",
      fieldsDetail: [
        {
          label: "Jam",
          key: "hour",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Selisih Batas Bawah",
          key: "before",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Selisih Batas Atas",
          key: "after",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      detailItems: [
        { hour: "00", before: null, after: null },
        { hour: "01", before: null, after: null },
        { hour: "02", before: null, after: null },
        { hour: "03", before: null, after: null },
        { hour: "04", before: null, after: null },
        { hour: "05", before: null, after: null },
        { hour: "06", before: null, after: null },
        { hour: "07", before: null, after: null },
        { hour: "08", before: null, after: null },
        { hour: "09", before: null, after: null },
        { hour: "10", before: null, after: null },
        { hour: "11", before: null, after: null },
        { hour: "12", before: null, after: null },
        { hour: "13", before: null, after: null },
        { hour: "14", before: null, after: null },
        { hour: "15", before: null, after: null },
        { hour: "16", before: null, after: null },
        { hour: "17", before: null, after: null },
        { hour: "18", before: null, after: null },
        { hour: "19", before: null, after: null },
        { hour: "20", before: null, after: null },
        { hour: "21", before: null, after: null },
        { hour: "22", before: null, after: null },
        { hour: "23", before: null, after: null },
      ],
      isSaveActive: false,
      activeRowIndex: 0
    };
  },
  mounted() { },
  methods: {
    clearTableStepCheck() {
      this.detailItems = [
              { hour: "00", before: null, after: null },
              { hour: "01", before: null, after: null },
              { hour: "02", before: null, after: null },
              { hour: "03", before: null, after: null },
              { hour: "04", before: null, after: null },
              { hour: "05", before: null, after: null },
              { hour: "06", before: null, after: null },
              { hour: "07", before: null, after: null },
              { hour: "08", before: null, after: null },
              { hour: "09", before: null, after: null },
              { hour: "10", before: null, after: null },
              { hour: "11", before: null, after: null },
              { hour: "12", before: null, after: null },
              { hour: "13", before: null, after: null },
              { hour: "14", before: null, after: null },
              { hour: "15", before: null, after: null },
              { hour: "16", before: null, after: null },
              { hour: "17", before: null, after: null },
              { hour: "18", before: null, after: null },
              { hour: "19", before: null, after: null },
              { hour: "20", before: null, after: null },
              { hour: "21", before: null, after: null },
              { hour: "22", before: null, after: null },
              { hour: "23", before: null, after: null },
            ]
    },

    cancelStepCheck() {
      this.$refs["modal-step-check"].hide();
    },
    saveStepCheck() {
      this.update();
      this.$refs["modal-step-check"].hide();
    },

    getKeyMetadata(param) {
      let key = ""
      switch (param) {
        case "relative_humidity_pc":
          // key = "BmkgSatu.metadata.qc.IRelative_Humidity_qc"
          key = "BmkgSatu.metadata.qc.IME48qc"
          break;
        
        case "temp_drybulb_c_tttttt":
          // key = "BmkgSatu.metadata.qc.ITemp_Drybulb_C_TTTTTT_qc"
          key = "BmkgSatu.metadata.qc.IME48qc"
          break;

        case "temp_wetbulb_c":
          // key = "BmkgSatu.metadata.qc.ITemp_Wetbulb_C_qc"
          key = "BmkgSatu.metadata.qc.IME48qc"
          break;

        case "pressure_QFE_mb_derived":
          // key = "BmkgSatu.metadata.qc.IPressure_QFE_Derived_qc"
          key = "BmkgSatu.metadata.qc.IME48qc"
          break;

        case "pressure_QFF_mb_derived":
          // key = "BmkgSatu.metadata.qc.IPressure_QFF_Derived_qc"
          key = "BmkgSatu.metadata.qc.IME48qc"
          break;

        case "temp_max_c_txtxtx":
          key = "BmkgSatu.metadata.qc.ITemp_Max_C_Txtxtx_qc"
          break;

        case "temp_min_c_tntntn":
          key = "BmkgSatu.metadata.qc.ITemp_Min_C_Tntntn_qc"
          break;
      
        default:
          break;
      }
      return key
    },

    getKeyDetailStepCheck(param) {
      let key = ""
      switch (param) {
        case "relative_humidity_pc":
          key = "mh_"
          break;
        
        case "temp_drybulb_c_tttttt":
          key = "td_"
          break;
        
        case "temp_wetbulb_c":
          key = "twc_"
          break;

        case "pressure_QFE_mb_derived":
          key = "mp_qfe_"
          break;

        case "pressure_QFF_mb_derived":
          key = "mp_qff_"
          break;
        
        case "temp_max_c_txtxtx":
          key = "tmc_"
          break;

        case "temp_min_c_tntntn":
          key = "tmic_"
          break;
      
        default:
          break;
      }
      return key
    },

    showStepCheck(item, index, event){
      this.isSaveActive = true
      this.clearTableStepCheck()
      this.selectedParameter = item.parameter
      this.metaParameter = item.key
      let key = this.getKeyMetadata(item.parameter)
      // let ketSC = this.getKeyDetailStepCheck(item.parameter)
      // let source = this.profile[key][item.key];
      // if (source && Object.keys(source).length > 0) {
      //   for (let scItem of this.detailItems) {
      //     for (let item in source) {
      //       if (scItem.hour == item) {
      //         scItem.before = source[item][ketSC+"before"]
      //         scItem.after = source[item][ketSC+"after"]
      //       }
      //     }
      //   }
      // }
      // console.log('this.selectedParameter', this.selectedParameter)
      let source = this.profile[key]
      if (source && Object.keys(source).length > 0) {
        for (var i = 0; i < this.detailItems.length; i++) {
          let index = this.padZero(i, 2)
          this.detailItems[i] = { 
            hour: index, 
            before: source["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_before"], 
            after: source["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_after"] 
          }
        }
      }

      // Change color for active 
      this.changeActiveColor(index)
    },

    // showStepCheck2(item, index, event) {
    //   console.log("item: " + item.parameter + "-" + index + ":", event);
    //   let atribut = item.parameter;
    //   let source = this.profile["BmkgSatu.metadata.qc.IME48"];
    //   this.scItems = [];
    //   let tmpIds = [];

    //   this.titleStepcheck = atribut;

    //   for (const key in source) {
    //     if (key.indexOf(atribut) > -1 && key.indexOf("stepcheck") > -1) {
    //       if (tmpIds.length > 0) {
    //         let keyArr = key.split("_");
    //         let number = keyArr[keyArr.length - 2];

    //         if (!isNaN(number) && tmpIds.indexOf(number) === -1) {
    //           this.scItems.push({ hour: number, before: source["qc_me48_" + atribut + "_stepcheck_" + number + "_before"], after: source["qc_me48_" + atribut + "_stepcheck_" + number + "_after"] });
    //           tmpIds.push(number);
    //         }
    //       } else {
    //         let keyArr = key.split("_");
    //         let number = keyArr[keyArr.length - 2];

    //         if (!isNaN(number)) {
    //           this.scItems.push({ hour: number, before: source["qc_me48_" + atribut + "_stepcheck_" + number + "_before"], after: source["qc_me48_" + atribut + "_stepcheck_" + number + "_after"] });
    //           tmpIds.push(number);
    //         }
    //       }
    //     }
    //   }

    //   this.scItems.sort(function(a, b) {
    //     return a.hour - b.hour;
    //   });

    //   this.$refs["modal-step-check"].show();
    // },
    padZero(val, n) {
      return `${"0".repeat(n)}${val}`.substr(-n, n);
    },

    generateAllField() {
      let data = []
      let subKey = this.getKeyDetailStepCheck(this.selectedParameter)
              
      for (let scItem of this.detailItems) {
        let ditem = {
                      "op": "assign",
                      "value": {
                          "key": "00",
                          "value": {}
                      }
                    }
        ditem.value.key = scItem.hour
        ditem.value.value[subKey+"before"] = scItem.before
        ditem.value.value[subKey+"after"] = scItem.after
        data.push(ditem)
      }

      return data
    },

    generateData() {
      let data = {};
      let post_data = {}

      switch(this.selectedParameter) {
        case "relative_humidity_pc":
          post_data = {
                        "BmkgSatu.metadata.qc.IRelative_Humidity_qc": {
                          "relative_humidity_qc": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;
        
        case "temp_drybulb_c_tttttt":
          post_data = {
                        "BmkgSatu.metadata.qc.ITemp_Drybulb_C_TTTTTT_qc": {
                          "temp_drybulb_c_tttttt": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;

        case "temp_wetbulb_c":
          post_data = {
                        "BmkgSatu.metadata.qc.ITemp_Wetbulb_C_qc": {
                          "temp_wetbulb_c": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;

        case "pressure_QFE_mb_derived":
          post_data = {
                        "BmkgSatu.metadata.qc.IPressure_QFE_Derived_qc": {
                          "pressure_QFE_mb_derived": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;

        case "pressure_QFF_mb_derived":
          post_data = {
                        "BmkgSatu.metadata.qc.IPressure_QFF_Derived_qc": {
                          "pressure_QFF_mb_derived": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;

        case "temp_max_c_txtxtx":
          post_data = {
                        "BmkgSatu.metadata.qc.ITemp_Max_C_Txtxtx_qc": {
                          "temp_max_c_txtxtx": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;

        case "temp_min_c_tntntn":
          post_data = {
                        "BmkgSatu.metadata.qc.ITemp_Min_C_Tntntn_qc": {
                          "temp_min_c_tntntn": {
                            "op": "multi",
                            "value":this.generateAllField()
                          }
                        }
                      }
          break;
      
        default:
          break;
      }
      return {post_data: post_data}

    },

    generatePostData() {
      let post_data = {
        "BmkgSatu.metadata.qc.IME48qc": { }
      }

      for (var i = 0; i < this.detailItems.length; i++) {
        let index = this.padZero(i, 2)
        // this.detailItems[i] = { hour: index, before: source["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_before"], after: source["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_after"] }
        post_data["BmkgSatu.metadata.qc.IME48qc"]["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_before"] = this.detailItems[i]['before']
        post_data["BmkgSatu.metadata.qc.IME48qc"]["qc_me48_"+this.selectedParameter.toLowerCase()+"_stepcheck_"+index+"_after"] = this.detailItems[i]['after']
      }

      return {post_data: post_data}
    },
    
    saveStepCheckContent() {
      // console.log("set Step Check : ", this.$route.query);
      let path = this.$route.query;
      let data = this.generatePostData() //this.generateData();
      let post_data = data.post_data
      // console.log("update - post_data", post_data);

      Service.updateStasiun(path.path, post_data)
        .then((response) => {
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            this.$swal({
              title: "Update Metadata Berhasil",
              text: "Data referensi Step Check",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$swal({
              title: "Unauthorized!",
              text: "Sesi anda telah habis. Logout dan silahkan login kembali",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (error.response.status == 404) {
            this.$swal({
              title: "Eror 404",
              text: "Data is not found on the server",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.t_data_status = "new";
            this.$swal({
              title: "Something wrong!",
              text: "" + error.response.status,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.showLoading = false;
        });
    },

    changeActiveColor(index) {
      this.items[this.activeRowIndex]._rowVariant = ''
      this.items[index]._rowVariant = 'primary'
      this.activeRowIndex = index
      this.items = [...this.items]
    }
    // saveStepCheckContent() {
    //   console.log('saveStepCheckContent')

    // }
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
