<template>
  <div>
    <b-card>
      <!-- <b-card-title class="mb-1 font-weight-bold">Quality Control - Configuration</b-card-title> -->
      <b-card-title class="mb-1 font-weight-bold">
        <span>QC: {{ title ? title : 'Stasiun ...' }}</span>
        <b-button v-if="!showLoading" @click.prevent="onRefresh()" variant="flat-light" class="float-right mt-n1 mb-2">
          <b-spinner v-if="isRefresh" variant="info" />
          <feather-icon v-if="!isRefresh" icon="RefreshCcwIcon" size="30" />
        </b-button>
      </b-card-title>
      <hr class="mt-1"/>
      <b-row>
        <b-col sm="12">
          <b-spinner v-if="!title" type="grow" label="Loading..." />
          <b-tabs>
            <!-- <b-tab title="General" active>
                        <general :profile="profile" :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
                        </b-tab>
                        <b-tab title="Entry User">
                        <entry-user :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
                        </b-tab> -->
            <b-tab v-for="(item, index) in filtered_list_entry" :key="item + index" :title="humanize(`${item}`)">
              <be-forms :profile="profile" :type_form="item" :roles="roles" @form="onRefreshForm($event)"/>
            </b-tab>
            <b-tab title="QC Rumus Otomatis">
              <be-auto-qc :profile="profile" 
                :path="path"
                :roles="roles"></be-auto-qc>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard, BCardTitle, BSpinner, BButton } from "bootstrap-vue";
// import General from "./componentbmkgentry/BeGeneral.vue";
// import EntryUser from "./componentbmkgentry/BeEntryUser.vue";
// import BeForms from "./componentbmkgentry/BeForms.vue";
// import BeAutoQc from "./componentbmkgentry/BeAutoQc";
import helper from "@/api/helper";
import axios from "axios";
import Params from "@/attr/metadata/params";
import BeForms from "../../metadatastasiun/metadata/componenmetadata/componentbmkgentry/BeForms.vue";
import BeAutoQc from "../../metadatastasiun/metadata/componenmetadata/componentbmkgentry/BeAutoQc.vue";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BCardTitle,
    BSpinner,
    BButton,
    // General,
    // EntryUser,
    BeForms,
    BeAutoQc,
  },
  //   props: ["profile", "roles"],
  data() {
    return {
      listbmkgentry: [],
      title: "",
      profile: "",
      roles: "",
      selectedMeteo: [],
      selectedFdih: [],
      selectedPias: [],
      selectedKu: [],
      path : "",
      isRefresh: false
    };
  },
  mounted() {
    // console.log("bmkgentry : ", this.profile);
    // console.log("BmkgSatu.metadata.qc.IME48 : ", this.profile["BmkgSatu.metadata.qc.IME48qc"]);
    this.path = this.$route.query.path;
    this.getStationData();
    // this.settingForm()
    this.listRoles();
  },
  methods: {
    onRefreshForm(val) {
      // console.log('QCStation - onRefreshForm', val)
      this.onRefresh()
    },

    updateSetting(event) {
      this.listbmkgentry = event;
    },
    humanize(str) {
      if (str == 'me48') str = 'sinoptik'
      var i, frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    onBack(event) {
      this.$emit("goBack", "back");
    },
    listQcAuto() {
      var Qc_sinop = this.profile["BmkgSatu.metadata.qc.IME48qc"];
    },
    getStationData() {
      this.showLoading = true;
      let url = helper.getBaseUrl() + this.path;
      axios.get(url, helper.getConfig()).then((response) => {
        if (response.status == 201 || response.status == 200 || response.status == 204) {
          this.profile = response.data;
          this.title = this.profile.station_name + " (" + this.profile.station_id + ")";
          this.settingForm()
        }
        this.showLoading = false;
      });
    },

    onRefresh() {
      // console.log('onRefresh')
      this.isRefresh = true;
      let url = helper.getBaseUrl() + this.path;
      axios.get(url, helper.getConfig()).then((response) => {
        if (response.status == 201 || response.status == 200 || response.status == 204) {
          // console.log('onRefresh')
          this.profile = response.data;
          this.title = this.profile.station_name + " (" + this.profile.station_id + ")";
          this.settingForm()
        }
        this.isRefresh = false;
      });
    },

    settingForm() {
      if (this.profile) {
        let be = this.profile["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];

        if (be.is_me48) {
          if (!this.selectedMeteo.includes("me48")) {
            this.selectedMeteo.push("me48");
          }
        }
        if (be.is_metar_speci) {
          if (!this.selectedMeteo.includes("metar")) {
            this.selectedMeteo.push("metar");
          }
        }
        if (be.is_pibal) {
          if (!this.selectedMeteo.includes("pilot_balon")) {
            this.selectedMeteo.push("pilot_balon");
          }
        }
        if (be.is_fklim) {
          if (!this.selectedFdih.includes("fklim")) {
            this.selectedFdih.push("fklim");
          }
        }
        if (be.is_iklim_mikro) {
          if (!this.selectedFdih.includes("iklim_mikro")) {
            this.selectedFdih.push("iklim_mikro");
          }
        }
        if (be.is_agm1a) {
          if (!this.selectedFdih.includes("agm1a")) {
            this.selectedFdih.push("agm1a");
          }
        }
        if (be.is_agm1b) {
          if (!this.selectedFdih.includes("agm1b")) {
            this.selectedFdih.push("agm1b");
          }
        }
        if (be.is_suhu_tanah) {
          if (!this.selectedFdih.includes("suhu_tanah")) {
            this.selectedFdih.push("suhu_tanah");
          }
        }
        if (be.is_psychrometer_assman) {
          if (!this.selectedFdih.includes("psychrometer_assman")) {
            this.selectedFdih.push("psychrometer_assman");
          }
        }
        if (be.is_op_penguapan) {
          if (!this.selectedFdih.includes("op_penguapan")) {
            this.selectedFdih.push("op_penguapan");
          }
        }
        // if (be.is_piche_penguapan) {
        //   if (!this.selectedFdih.includes("piche_penguapan")) {
        //     this.selectedFdih.push("piche_penguapan");
        //   }
        // }
        // if (be.is_lysimeter) {
        //   if (!this.selectedFdih.includes("lysimeter")) {
        //     this.selectedFdih.push("lysimeter");
        //   }
        // }
        if (be.is_lysimeter_gundul) {
          if (!this.selectedFdih.includes("lysimeter_gundul")) {
            this.selectedFdih.push("lysimeter_gundul");
          }
        }
        if (be.is_lysimeter_komoditi) {
          if (!this.selectedFdih.includes("lysimeter_komoditi")) {
            this.selectedFdih.push("lysimeter_komoditi");
          }
        }
        if (be.is_lysimeter_berumput) {
          if (!this.selectedFdih.includes("lysimeter_berumput")) {
            this.selectedFdih.push("lysimeter_berumput");
          }
        }
        if (be.is_gunbellani) {
          if (!this.selectedFdih.includes("gunbellani")) {
            this.selectedFdih.push("gunbellani");
          }
        }
        if (be.is_lama_penyinaran) {
          if (!this.selectedPias.includes("lama_penyinaran")) {
            this.selectedPias.push("lama_penyinaran");
          }
        }
        if (be.is_aktinograph) {
          if (!this.selectedPias.includes("aktinograph")) {
            this.selectedPias.push("aktinograph");
          }
        }
        if (be.is_thermohigograph) {
          if (!this.selectedPias.includes("thermohigograph")) {
            this.selectedPias.push("thermohigograph");
          }
        }
        if (be.is_barograph) {
          if (!this.selectedPias.includes("barograph")) {
            this.selectedPias.push("barograph");
          }
        }
        if (be.is_perawanan) {
          if (!this.selectedPias.includes("perawanan")) {
            this.selectedPias.push("perawanan");
          }
        }
        if (be.is_form_hujan) {
          // if (!this.selectedPias.includes("form_hujan")) {
          //   this.selectedPias.push("form_hujan");
          // }
          if (!this.selectedPias.includes("form_hujan_harian")) {
            this.selectedPias.push("form_hujan_harian");
          }
        }
        if (be.is_hujan_helman) {
          if (!this.selectedPias.includes("hujan_helman")) {
            this.selectedPias.push("hujan_helman");
          }
        }
        if (be.is_kelembapan_tanah) {
          if (!this.selectedPias.includes("kelembapan_tanah")) {
            this.selectedPias.push("kelembapan_tanah");
          }
        }
        if (be.is_fenologi) {
          if (!this.selectedPias.includes("fenologi")) {
            this.selectedPias.push("fenologi");
          }
        }

        if (be.is_kimia_air_hujan) {
          if (!this.selectedKu.includes("kimia_air_hujan")) {
            this.selectedKu.push("kimia_air_hujan");
          }
        }
        if (be.is_spm) {
          if (!this.selectedKu.includes("suspended_particulate_matter")) {
            this.selectedKu.push("suspended_particulate_matter");
          }
        }
        if (be.is_so2_no2) {
          if (!this.selectedKu.includes("so2no2")) {
            this.selectedKu.push("so2no2");
          }
        }
        if (be.is_aerosol) {
          if (!this.selectedKu.includes("aerosol")) {
            this.selectedKu.push("aerosol");
          }
        }
        this.listbmkgentry = [...this.selectedMeteo, ...this.selectedFdih, ...this.selectedPias, ...this.selectedKu];

        // console.log('this.listbmkgentry ', this.listbmkgentry)
      }

      
    },

    listRoles() {
      let userLogin = JSON.parse(localStorage.getItem("userLogin"));
      let role = userLogin.role;
      let roles = {
        isRoleAdd: false,
        isRoleEdit: false,
        isRoleView: false,
        isRoleDelete: false,
      };
      if (role != "admin") {
        let myRoles = helper.getMenuRoles(userLogin.listperm);
        console.log("myRoles", myRoles);

        if (myRoles.hasOwnProperty("Station")) {
          if (myRoles.Station.includes("Add")) {
            roles["isRoleAdd"] = true;
          }
          if (myRoles.Station.includes("Modify")) {
            roles["isRoleEdit"] = true;
          }
          if (myRoles.Station.includes("View")) {
            roles["isRoleView"] = true;
          }
          if (myRoles.Station.includes("Delete")) {
            roles["isRoleDelete"] = true;
          }

          if (myRoles.Station.includes("HistoricalNotes")) {
            this.isHistoricalNotes = true;
          }
          if (myRoles.Station.includes("Geography")) {
            this.isGeography = true;
          }
          if (myRoles.Station.includes("Photos")) {
            this.isPhotos = true;
          }
          if (myRoles.Station.includes("Element")) {
            this.isElement = true;
          }
          if (myRoles.Station.includes("LocalMaps")) {
            this.isLocalMaps = true;
          }
        }
      } else {
        roles["isRoleAdd"] = true;
        roles["isRoleEdit"] = true;
        roles["isRoleView"] = true;
        roles["isRoleDelete"] = true;

        this.isHistoricalNotes = true;
        this.isGeography = true;
        this.isPhotos = true;
        this.isElement = true;
        this.isLocalMaps = true;
      }
      // console.log('roles', roles)
      this.roles = roles;
    },
  },
  computed: {
    filtered_list_entry() {
      // return hanya item yang ada qc nya
      return this.listbmkgentry.filter((item) => Params.GET_QC_STATION_KEY(item));
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
