<template>
  <div style="font-size: 0.90rem;">
    <div class="row">
      <b-col sm="6">
        <b-card class="border">
          <b-form-radio id="barometer_air_raksa" name="barometer" v-model="barometer" value="air_raksa" inline>
            <label for="barometer_air_raksa">Barometer Air Raksa</label>
          </b-form-radio>
          <b-form-radio id="barometer_digital" name="barometer" v-model="barometer" value="digital" inline>
            <label for="barometer_digital">Barometer Digital</label>
          </b-form-radio>
          <b-button @click="updateBarometer" variant="success" class="mr-2" size="sm">Update</b-button>
        </b-card>
        <b-card no-body class="border">
          <!-- table -->
          <b-row class="font-weight-bolder mb-2 mt-1 ml-1">
            <b-col sm="6">
              <span class="" style="font-size: 1.20rem;"> Pressure Correction Table </span>
            </b-col>
            <b-col sm="6" class="">
              <b-button @click.prevent="addPressureCorrection" variant="primary" class="mr-2 float-right" size="sm" >
                <feather-icon
                  icon="EditIcon"
                  class=""
                />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" class="">
              <b-table hover :items="items" :fields="fields" fixed thead-class="text-center">
                <!-- <template #cell(parameter)="row">
                  <span class="font-weight-bold text-dark w-100"> {{ row.item.parameter }}</span>
                </template> -->

                <template #cell(temp)="row">
                  <label size="sm" type="text" class="text-center w-100" disabled> {{row.item.temp}} </label>
                </template>

                <template #cell(pc_qff)="row">
                  <label size="sm" type="text" class="text-center w-100" disabled> {{row.item.pc_qff}} </label>
                </template>

                <template #cell(pc_qfe)="row">
                  <label size="sm" type="text" class="text-center w-100" disabled> {{row.item.pc_qfe}} </label>
                </template>

                <template #cell(action)="row">
                  <div class="w-100" style="text-align: center">
                    <b-button @click="onDeleteRow(row.item.temp)" variant="danger" class="text-center align-center" size="sm">
                      <feather-icon
                        icon="Trash2Icon"
                        class=""
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <!-- <b-row class="float-right">
          <div>
            <b-button @click.prevent="onBack" variant="danger" class="mr-2" size="sm">Back</b-button>
          </div>
        </b-row> -->
      </b-col>
    </div>
    <b-modal
      v-model="show"
      title="Add Pressure Correction"
      :header-bg-variant="headerBgVariant"
      :no-close-on-backdrop="true"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="3" class="font-weight-bolder">TEMP</b-col>
          <b-col cols="3" class="font-weight-bolder">QFF</b-col>
          <b-col cols="3" class="font-weight-bolder">QFE</b-col>
          <b-col cols="3"/>
        </b-row>

        <b-row
          class="row-margin mb-1"
          v-for="(item, index) in NEW_ITEMS"
          :key="index"
        >
          <b-col cols="3">
            <b-form-input size="sm" type="number" v-model.number="item.temp" class="text-center" />
          </b-col>
          <b-col cols="3">
            <b-form-input size="sm" type="number" v-model.number="item.qff" class="text-center" />
          </b-col>
          <b-col cols="3">
            <b-form-input v-on:keydown.tab="onTab($event, index)" size="sm" type="number" v-model.number="item.qfe" class="text-center" />
          </b-col>
          <b-col cols="3">
            <b-button
              v-if="(NEW_ITEMS.length > 1)"
              variant="warning"
              size="sm"
              @click="onDeleteInput($event, index)"
            > 
              <span class="font-weight-bolder">-</span>
            </b-button>
            &nbsp;
            <b-button
              v-if="(index == (NEW_ITEMS.length - 1))"
              variant="success"
              size="sm"
              @click="onAddInput($event, index)"
              class=""
            >
              <span class="font-weight-bolder">+</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-row class="w-100">
          <b-col cols="9">
            <span>NB: Klik tombol (+) atau gunakan "Tab" keyboard pada field QFE akhir untuk menambah field yang baru </span>
          </b-col>
          <b-col cols="3">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="onSubmit"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard, BFormGroup, BFormSelect, BTable, BButton, BFormRadio, BFormInput, BContainer } from "bootstrap-vue";
import vSelect from "vue-select";
import Service from "@/api/metadata";
import axios from "axios";
import helper from "@/api/helper";
import error_handler from "@/api/error_handler";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BFormGroup,
    BFormSelect,
    BTable,
    BButton,
    BFormRadio,
    BFormInput,
    BContainer,
    vSelect,
  },
  props: ["profile"],
  data() {
    return {
      show: false,
      headerBgVariant: 'info',
      path: "",
      barometer: "",
      fields: [{key: 'temp', label: 'TEMP'}, {key: 'pc_qff', label: 'QFF'}, {key: 'pc_qfe', label: 'QFE'}, {key: 'action', label: 'Action'}],
      items: [],
      newItem: {
        temp: null,
        qff: null,
        qfe: null
      },
      showLoading: false,
      NEW_ITEMS: [
        {
          temp: null,
          qff: null,
          qfe: null
        }
      ]
    };
  },
  mounted() {
		this.path = this.$route.query.path;
    if (this.profile?.barometer_air_raksa) {
      this.barometer = "air_raksa";
    }
    if (this.profile?.barometer_digital) {
      this.barometer = "digital";
    }

    this.refreshPressureCorrectionTable()
  },
  methods: {

    onDeleteInput(event, index) {
      this.NEW_ITEMS.splice(index, 1)
    },

    onAddInput(event, index) {
      if (index == this.NEW_ITEMS.length - 1) {
        this.NEW_ITEMS.push({
          temp: null,
          qff: null,
          qfe: null
        });
      }
    },

    onTab(event, index) {
      if (index == this.NEW_ITEMS.length - 1) {
        this.NEW_ITEMS.push({
          temp: null,
          qff: null,
          qfe: null
        });
      }
    },

    refreshPressureCorrectionTable() {
      this.items = []
    
      if (this.profile) {
        let pressureCorrection = this.profile["BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc"].qc_pressure_correction_qfe_qff;
        if (pressureCorrection && Object.keys(pressureCorrection).length > 0) {
          for (var i in pressureCorrection) {
            this.items.push({ "temp": i, "pc_qff": pressureCorrection[i].pc_qff, "pc_qfe": pressureCorrection[i].pc_qfe })
          }

          this.items.sort(function(a, b){return a.temp - b.temp});
        }
      }
    }, 

    updateBarometer() {
      const update_data = {
        barometer_air_raksa: this.barometer === "air_raksa",
        barometer_digital: this.barometer === "digital",
      };

      const update_url = helper.getBaseUrl() + this.path;

      axios
        .patch(update_url, update_data, helper.getConfig())
        .then((response) => {
          if (
            response.status == 201 ||
            response.status == 200 ||
            response.status == 204
          ) {
            this.$swal({
            title: "Update Data berhasil!",
            text: "Update Data Barometer Stasiun",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
            });
            this.showLoading = false;

            this.$emit("usageUpdate", update_data);
          }
        })
        .catch((error) => {
          this.showLoading = false;
          error_handler(this, error, "Barometer Stasiun", "Update Data Error");
        });
    },

    generateData() {
      let me48 = this.profile["BmkgSatu.metadata.qc.IME48"];
      let data = {};

      for (const key in me48) {
        for (var i in this.items) {
          if (key.indexOf(this.items[i].parameter.toLowerCase()) > -1) {
            if (key.indexOf("_min") > -1) {
              data[key] = this.items[i].min;
            } else if (key.indexOf("_max") > -1) {
              data[key] = this.items[i].max;
            } else if (key.indexOf("_values") > -1) {
              data[key] = this.items[i].special_value;
            }
            break;
          }
        }
      }

      return data;
    },

    onSubmit() {
      let values = []
      let isComplete = true
      for (let item of this.NEW_ITEMS) {
        // console.log('item', item)
        if(item.temp !== null && item.qff !== null && item.qfe !== null && item.temp !== "" && item.qff !== "" && item.qfe !== "") {
          values.push({
                        "op": "assign",
                        "value": {
                            "key": ""+item.temp,
                            "value": {"pc_qfe": item.qfe, "pc_qff": item.qff}
                        }
                      })
        }
        else {
          // console.log('else', item.temp !== null, item.qff !== null, item.qfe !== null, item.temp !== "", item.qff !== "", item.qfe !== "", item.qfe)
          isComplete = false
        }
      }
      if (!isComplete) {

         this.$swal({
            title: "Data Belum Lengkap",
            text: "Periksa kembali kolom data yang masih kosong",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
          });
        return
      }
      const update_data = {
        "BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc": {
            "qc_pressure_correction_qfe_qff": {
                "op": "multi",
                "value": values
            }
        }
      }
      const update_url = helper.getBaseUrl() + this.path;
      axios
        .patch(update_url, update_data, helper.getConfig())
        .then((response) => {
          if (
            response.status == 201 ||
            response.status == 200 ||
            response.status == 204
          ) {
            this.show = false
            this.updateData()
            this.refreshPressureCorrectionTable()
            
            this.$swal({
            title: "Update Data berhasil!",
            text: "Update Table Pressure Correction",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
            });

            this.showLoading = false;

            

            // this.$emit("usageUpdate", update_data);
          }
        })
        .catch((error) => {
          this.showLoading = false;
          error_handler(this, error, "Pressure Correction", "Update Data Error");
        });
    },

    updateData() {
      // console.log('updateData')
      let pressureCorrection = this.profile["BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc"].qc_pressure_correction_qfe_qff;
      // if (pressureCorrection && Object.keys(pressureCorrection).length > 0) {
      //   pressureCorrection[""+this.newItem.temp] = {
      //                                                 "pc_qfe": this.newItem.qfe,
      //                                                 "pc_qff": this.newItem.qff
      //                                               }
      // }
      // else {
      //   pressureCorrection = {}
      //   pressureCorrection[""+this.newItem.temp] = {
      //                                                 "pc_qfe": this.newItem.qfe,
      //                                                 "pc_qff": this.newItem.qff
      //                                               }
      // }
      if (pressureCorrection == null) {
        this.profile["BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc"].qc_pressure_correction_qfe_qff = {}
        pressureCorrection = this.profile["BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc"].qc_pressure_correction_qfe_qff
      }

      if (this.NEW_ITEMS.length > 0) {
        for (let item of this.NEW_ITEMS) {
          pressureCorrection[""+item.temp] = {
                                              "pc_qfe": item.qfe,
                                              "pc_qff": item.qff
                                            }
        }
      }

    },

    addPressureCorrection() {
      this.NEW_ITEMS = [
        {
          temp: null,
          qff: null,
          qfe: null
        }
      ]
      this.show = true
    },

    onDeleteRow (key) {
      const update_data = {
        "BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc": {
        "qc_pressure_correction_qfe_qff": {
            "op": "del",
            "value": ""+key
        }
    }
      }
      const update_url = helper.getBaseUrl() + this.path;
      axios
        .patch(update_url, update_data, helper.getConfig())
        .then((response) => {
          if (
            response.status == 201 ||
            response.status == 200 ||
            response.status == 204
          ) {
            this.show = false

            let pressureCorrection = this.profile["BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc"].qc_pressure_correction_qfe_qff;
            if (pressureCorrection && Object.keys(pressureCorrection).length > 0) {
              delete pressureCorrection[key];
            }

            this.refreshPressureCorrectionTable()
            
            this.$swal({
            title: "Delete data berhasil!",
            text: "Delete Data Temp : "+key,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
            });

            this.showLoading = false;

            

            // this.$emit("usageUpdate", update_data);
          }
        })
        .catch((error) => {
          this.showLoading = false;
          error_handler(this, error, "Pressure Correction", "Update Data Error");
        });
    },

    onBack() {

    }

  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
